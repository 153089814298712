import React from 'react'
import styled from 'styled-components'
import { SP_WIDTH } from '../config/Config';

const Div = styled.div`
background-color: #fff;
border-radius: 20px;
box-shadow: 0 10px 40px -10px rgba(0,0,0,.3);
overflow: hidden;
padding: 48px 32px;
@media screen and (max-width: ${SP_WIDTH}px) {
  padding: 20px;
}
`

const ShadowCard = ({ children }: { children: any | any[] }) => (
  <Div>{children}</Div>
)

export default ShadowCard