import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import SubTitle from "../components/subtitle";
const image = require("../images/photos/006.jpg");
import styled from "styled-components";
import ShadowCard from "../components/shadow-card";
import PhotoImage from "../components/photo-image";

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .flex-child {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
  }

  .greet {
    padding: 16px 0;

    .sentence {
      line-height: 26px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      margin-top: 24px;
      text-align: right;
    }
  }

  .sub {
    padding: 16px;
  }
`;

const H3 = styled.h3``;

const GreetPage = () => (
  <Layout>
    <Section>
      <ShadowCard>
        <SubTitle>院長よりご挨拶</SubTitle>
        <FlexDiv>
          <div className="flex-child greet">
            <p className="sentence">
              先代である父、正博が昭和62年に八女市内で大渕耳鼻咽喉科医院を開業し30年以上が経過しました。
              <br />
              私自身もまた幼少期を八女市で過ごした為、クリニックを継承し地元で診療できることを大変嬉しく思っております。
            </p>
            <p className="sentence">
              以前は耳鼻咽喉科というと、他の診療科と異なり採血やレントゲンなど目に見える形で所見を説明することが非常に難しい分野でした。
              <br />
              しかし医療や科学技術の進歩に伴い、徐々に目に見える形で説明できることが増えてきました。
            </p>
            <p className="sentence">
              令和1年9月にクリニックを移転新築した事に伴い、院内の医療機器等も多くを一新し、みなさまに目に見える形で少しでもわかりやすく説明できるような器具をそろえました。私自身も、できるだけ目に見える形で説明を行う様に意識しながら診療しています。
            </p>
            <p className="sentence">
              また八女公立病院、筑後市立病院、聖マリア病院の地域連携登録医として、また出身大学である久留米大学の耳鼻咽喉科・頭頸部外科とも連携しながら、この地域のみなさんのお役に少しでもたてるよう、努めていきたいと思いますので、スタッフ一同、何卒宜しくお願い致します。
            </p>
            <p className="name"><span style={{marginRight: 16}}>院長</span>大渕 晋輔</p>
          </div>
          {/* <div className="flex-child">
            <PhotoImage src={image} caption="院長 大渕晋輔" />
          </div> */}
        </FlexDiv>
      </ShadowCard>
    </Section>
    <Section>
      <ShadowCard>
        {/* <SubTitle>院長経歴</SubTitle> */}
        <FlexDiv>
          {/* <div className="flex-child greet">
            <p>
              〇〇大学医学部卒業
              <br />
              〇〇〇〇〇〇
              <br />
              〇〇〇〇〇〇
              <br />
              〇〇〇〇〇〇
              <br />
            </p>
          </div> */}
          <div className="flex-child career">
            <H3>所属学会</H3>
            <p className="sub">
              日本耳鼻咽喉科学会
              <br />
              日本小児耳鼻咽喉科学会
            </p>
          </div>
          <div className="flex-child career">
            <H3>資格</H3>
            <p className="sub">
              日本耳鼻咽喉科学会認定専門医
              <br />
              厚生労働省認定補聴器適合判定医
              <br />
              日本耳鼻咽喉科学会認定補聴器相談医
              <br />
              麻酔科標榜医（麻 第10833号）
              <br />
              日本医師会主催ACLSコース修了
            </p>
          </div>
        </FlexDiv>
      </ShadowCard>
    </Section>
  </Layout>
);

export default () => <GreetPage />;
